/*------------- Variable ------------*/

:root {
  --iq-primary: #e50914;
  --iq-primary-hover: #bf000a;
  --iq-light-primary: #d7d3f8;
  --iq-secondary: #6c757d;
  --iq-white: #ffffff;
  --iq-black: #000000;
  --iq-title-text: #23203c;
  --iq-body-text: #D1D0CF;
  --iq-body-bg: #141414;
  --iq-bg1: #191919;
  --iq-body-border: #393750;
  --iq-gold: #E6C75F;
  --iq-black: #000000;
  --iq-form-gradient-color: rgba(11, 1, 2, 0);
  --iq-to-gradient-color: rgba(255, 55, 65, 0.3);}
